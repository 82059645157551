import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import StudentHeader from "../../../../components/student-header"
import ImageHero from "../../../../components/image-hero"
import SectionHeader from "../../../../components/section-header"
import Footer from "../../../../components/footer"
import VideoEmbed from "../../../../components/video-embed"
import ButtonSign from "../../../../components/button-sign"
import ButtonOutline from "../../../../components/button-outline"
import QROverlay from "../../../../components/qr-overlay"

const img1 = require("../../../../images/utan-demokratiska-rattigheter/ester-1.jpg")
const img2 = require("../../../../images/utan-demokratiska-rattigheter/ester-2.jpg")
const img3 = require("../../../../images/utan-demokratiska-rattigheter/ester-3.jpg")
const img4 = require("../../../../images/utan-demokratiska-rattigheter/ester-4.jpg")
const imgEster = require("../../../../images/utan-demokratiska-rattigheter/ester-portratt.png")

import "../../../../scss/site.scss"
//import StickyCta from "../../../../components/sticky-cta"

const EsterHenning = props => {
  const [showOverlay, setShowOverlay] = useState(false)
  //const [caption, setCaption] = useState(null)
  //const [url, setUrl] = useState(null)
  const [heading, setHeading] = useState(null)
  const [text, setText] = useState(null)
  const [linkText, setLinkText] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  /*const handleShowOverlay = (caption, url) => {
    if (caption) setCaption(caption)
    if (url) setUrl(url)
    setShowOverlay(!showOverlay)
  }*/

  const handleShowOverlay = (heading, text, linkText) => {
    if (heading) setHeading(heading)
    if (text) setText(text)
    if (linkText) setLinkText(linkText)
    setShowOverlay(!showOverlay)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Ester Henning | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/ester-henning"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/ester-henning"
        ></meta>
        <meta property="og:title" content="Ester Henning"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <StudentHeader />
        <div role="main" id="main" className="o-main c-main-background">
          <ImageHero
            title="Ester Henning"
            img={imgEster}
            alt="Porträtt av Ester Henning"
          >
            <p>
              Levde 1887-1985. Ester var konstnär som kom från ett fattigt hem.
              Som ung blev hon sjuk i sjukdomen Schizofreni. Ester levde sen
              resten av sitt liv inlåst på olika sjukhus.
              <br />
              <span className="c-description">
                Foto: Mentalvårdsmuseet i Säter
              </span>
            </p>
            <ul className="c-hero__questions">
              <li>
                <h3 className="c-hero__questions__title">
                  Huvudfråga: På vilket sätt saknade Ester Henning demokratiska
                  rättigheter?
                </h3>
              </li>
              <li>Lyssna på bildberättelsen och arbeta med källorna.</li>
            </ul>
          </ImageHero>

          <section
            className="c-content-section c-content-section--transparent"
            id="berattelse"
            aria-label="Berättelse"
          >
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <ButtonSign
                    text="Svenskt teckenspråk"
                    to="https://vimeo.com/527309076"
                  />
                  <VideoEmbed url="https://player.vimeo.com/video/519516699?api=1&player_id=vimeoplayer&title=0&byline=0&portrait=0&color=0BA126" />
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="kalla-1-flygfoto"
          >
            <SectionHeader id="kalla-1-flygfoto" text="Källa 1 Flygfoto " />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Flygfoto
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img1}
                              alt="Svartvitt foto på flera stora byggnader sedda från luften. Byggnaderna liknar varandra, är avlånga och har många fönster på rad och formar tillsammans en kvadrat. Runt om byggnaderna finns åkrar, träd och andra mindre hus."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Flygfoto över Beckomberga sjukhus, Stockholm, 1932
                              (foto: Oscar Bladh, Stadsmuseet i Stockholm).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad föreställer bilden?</li>
                          <li>Varför tror du någon har tagit den?</li>
                          <li>Vilka tankar och frågor väcker bilden?</li>
                          <li>Vad berättar den om Ester och hennes liv?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="kalla-2-fotografi"
          >
            <SectionHeader id="kalla-2-fotografi" text="Källa 2 Fotografi" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Fotografi
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img2}
                              alt="Svartvitt foto på en stor sal med fler sängar. Sängarna ser alla likadana ut med gavlar av stål och med vita överkast. På alla sängar hänger likadana vita handdukar. Mellan sängarna står små sängbord utan saker på. Väggarna är helt kala och tomma. Längst bort i bild finns ett stort fönster. Solen lyser utanför bakom de fördragna gardinerna."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Fotografi av sovsal på Beckomberga sjukhus,
                              Stockholm, 1938 (foto: Nils Erik Åzelius,
                              Stadsmuseet i Stockholm).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad föreställer bilden?</li>
                          <li>Varför tror du någon har tagit den?</li>
                          <li>Vilka tankar och frågor väcker bilden?</li>
                          <li>Vad berättar den om Ester och hennes liv?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="kalla-3-broderi"
          >
            <SectionHeader id="kalla-3-broderi" text="Källa 3 Broderi" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Broderi
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img3}
                              alt="En broderad tavla som föreställer fyra kvinnor som står i grunt vatten. Kvinnorna har baddräkter i olika färger och hattar på sig. I bakgrunden finns gräs, träd och små hus i massor av olika färger."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Broderi föreställande fyra kvinnor i baddräkt.
                              Broderat av Ester Henning, 1960-tal (tillhör
                              Konstmuseet, Skövde kulturhus och Janny-Lisa
                              Clasons stiftelse).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__text">
                        Broderi föreställande fyra kvinnor i baddräkt. Broderat
                        av Ester Henning, 1960-tal.
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad föreställer broderiet?</li>
                          <li>
                            Vad tror du Ester vill berätta med sitt broderi?
                          </li>
                          <li>Vilka tankar och känslor väcker det?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="kalla-4-malning"
          >
            <SectionHeader id="kalla-4-malning" text="Källa 4 Målning" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Målning
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img4}
                              alt="En målad tavla på hus omgivna av träd och buskar. Längst fram står en röd låg träbyggnad och bakom den ett lite större, gult hus. Bakom det gula huset finns en byggnad till, men bara taket syns."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Tavla av köksavdelningen och festsalen vid
                              Beckomberga sjukhus målat på Beckomberga sjukhus
                              av Ester Henning, 1960 (tillhör Konstmuseet,
                              Skövde kulturhus och Janny-Lisa Clasons
                              stiftelse).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad föreställer målningen?</li>
                          <li>
                            Vad tror du Ester vill berätta med sin målning?
                          </li>
                          <li>Vilka tankar och känslor väcker den?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="c-image-teaser__action--wide">
            <ButtonOutline
              text="Länk och QR-kod till denna sida"
              title="Ester Henning"
              onClick={() => {
                handleShowOverlay(
                  "Utan demokratiska rättigheter: Ester Henning",
                  "Öppna med QR-kod eller skriv in länk:",
                  "bit.ly/esterhenning"
                )
              }}
            ></ButtonOutline>
          </div>
        </div>
        <Footer />
        {showOverlay && (
          <QROverlay
            show={showOverlay}
            heading={heading}
            text={text}
            linkText={linkText}
            toggleShow={handleShowOverlay}
          />
        )}
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default EsterHenning
